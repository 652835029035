
export function useCities() {
    const cities = useState('cities', () => [])
    const cityId = useState('cityId', () => 1)

    async function fetchCities() {
        return useHttpGet('cities')
            .then((response) => {
                cities.value = response.data.value
            })
    }

    async function getCityBySlug(slug) {
        return useHttpGet('cities/slug/' + slug)
    }

    async function getCityById(id) {
        return useHttpGet('cities/' + id)
    }

    return {
        cities,
        cityId,
        fetchCities,
        getCityBySlug,
        getCityById
    }
}
